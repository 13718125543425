import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
// import Skeleton from 'react-loading-skeleton';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
// import useHandleAddProduct from '../../../utils/handleAddProduct';
// import YoutubeVideoHero from '../../../components/general/youtubeVideoHero';
import CustomButton from '../../../components/general/button';
// import ShowCurrencies from '../../../utils/showCurrencies';
// import ConvertAUD from '../../../utils/convertAUD';
import ConvertUSD from '../../../utils/convertUSD';
// import Sticky from 'react-sticky-el';
// import moment from 'moment';
// import { endDateOfAppealCampaign, endDateOfGazaCampCampaign, endDateOfLimbsAppealCampaign } from '../details';
import getSymbolFromCurrency from 'currency-symbol-map';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import SelectCurrency from '../../../components/general/selectCurrency';
import creditImg from '../../../images/payment/credit-cards.png';
// import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import handleDaysLeft from '../handleDaysLeft';

const AppealCurrentProductUAT = ({ lang = 'en', currentProduct, goal, campaign, selectedGivingProducts }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [addProduct] = useHandleAddProduct();
  // const location = useLocation();
  // const [isRefugeePage, setIsRefugeePage] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  // const [isTabsFixed, setIsTabsFixed] = useState(false);

  const moreInfo = [
    {
      title: '',
      description: (
        <div className="text-[16px] md:text-[18px]">
          The ongoing conflict has left many without shelter, education, or basic necessities, casting a dark shadow over what should be formative
          years for a child. By providing food and shelter, you can offer them not just survival, but a glimmer of hope for a brighter future.
          Together, we can make a profound difference in their lives because no child should have to face the world alone.
        </div>
      ),
      box_title: (
        <div className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl ">They’ve lost everything — but we can still give them hope.</div>
      ),
      box_description: (
        <div className="text-[16px] md:text-[18px]">
          Right now, in Gaza, thousands of children are waking up to a world without their parents. They’ve survived the unimaginable —destruction,
          displacement — only to be left orphaned, alone, and deeply traumatised. The conflict has robbed them of their families, homes, safety, and
          childhood. These children urgently need protection, stability, and love.
          <br />
          <br />
          <ul className="list-disc pl-4">
            <li>Right now, in Gaza, thousands of children are waking up to a world without their parents.</li>
            <li>They’ve survived the unimaginable —destruction, displacement — only to be left orphaned, alone, and deeply traumatised.</li>
            <li>
              The conflict has robbed them of their families, homes, safety, and childhood. These children urgently need protection, stability, and
              love.
            </li>
          </ul>
          <br />
          Every day in Gaza holds the possibility of being the last. Yet, despite the darkness, these children refuse to give up. Their resilience is
          unmatched — and so is their need. We must act now.
          <br />
          <br />
          MATW has a 100% Zakat & Donation Policy – Every penny you give helps save lives.
          <br />
          <br />
          Our MATW team is on the ground every day, delivering urgent relief to orphans families in need with your support powering every step. Your
          generosity isn’t just a donation — it’s a lifeline.
          <br />
          <br />
          <p className="text-base text-DarkerBlue sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-gotcha- font-brandingBold mb-2">
          {' '}
          From Emergency Relief to Long-Term Support
        </p>
        <div className="text-[16px] md:text-[18px]">
          Since October 7, 2023, your donations have helped us:
          <br />
          <br />
          <ul className="list-disc pl-4">
            <li>Deliver over 344 aid trucks into Gaza packed with food, water, and life-saving supplies.</li>
            <li>Build a makeshift village, now home to 5,000+ displaced people, including over 1,000 orphaned children.</li>
            <li>
              Launch a maternal care project, supporting 10,000 new mothers and babies with essential items like formula, clothing, and diapers.
            </li>
            <li>
              Develop new water lines for 150,000 individuals, providing long-term access to clean, safe drinking water — a vital Sadaqah Jariyah.
            </li>
          </ul>
          <br />
          <p className="text-base text-DarkerBlue sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-gotcha- font-brandingBold mb-2">
            {' '}
            How We’re Reaching Orphans in Gaza.
          </p>
          MATW is proud to be one of the few charities working in partnership with the Jordan Hashemite Charity Organisation, the Egyptian Youth
          Council, and the Egyptian Red Crescent. These connections allow us to deliver immediate relief inside Gaza — fast, efficiently, and directly
          into the hands of those who need it most.
          <br />
          We’ve also established reserves on the ground to ensure quick response in times of urgent need.
          <br />
          <br />

          <p className="text-base text-DarkerBlue sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-gotcha- font-brandingBold mb-2">
            Your support protects their future.
          </p>
          These children are not just victims of conflict — they are the future of Gaza. With your Zakat and Sadaqah, you can give an orphan a safe
          place to sleep, warm clothes to wear, food to eat, and the care every child deserves.
          <br />
          <br />
          Their pain is unimaginable — but your support can give them back a sense of hope, belonging, and safety.
          <br />
          <br />
          Be their shelter. Be their strength. Be their hope. Donate now. Save a life. Shape the future.
        </div>
        </div>
      ),
    },
    {
      title: 'Your support is needed now more than ever.',
      description: `You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100% Zakat-applicable.`,
      box_title: '',
      box_description: ``,
    },
    {
      title: 'Empower Lives with Prosthetic Support',
      description: (
        <p>
          Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone
          in need. Every contribution, big or small, goes toward covering the costs of acquiring, customizing, and fitting a prosthetic limb.
          Together, we can offer the gift of mobility, independence, and a brighter future for individuals who deserve a chance to live fully again.
          Join us in making a profound impact today!
          <br />
          <br />
          Among them, thousands have become amputees - losing an arm, a leg or in some cases all their limbs. <i>Imagine</i> not being able to stand
          in Salat, or wrap your arms around your loved ones!
          <br />
          <br />
          The people of Palestine have already lost so much. They’ve been forced from their homes, lost their livelihoods, their belongings and their
          loved ones - don’t let them lose their dignity or hope.
          <br />
          <br />
          <strong>
            As the official named partner of the Jordan Hashemite Charity Organisation, Muslims Around The World Project (MATW Project), will be
            providing prosthetic limbs in Gaza to amputees through a new ground-breaking technology that can fit a limb within one hour!
          </strong>
          <br />
          <br />
          <strong>
            A Sadaqah Jariyah like this not only has the power to save lives but will also reap immense rewards for your Akhirah Insha’Allah!
          </strong>
          <br />
          <br />
          <strong>Help us provide Limbs of Hope for Gazans in need and restore dignity and hope. Donate Now.</strong>
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title: 'About the conflict',
      description: (
        <div>
          <p>
            More than 49,000 Palestinians have lost their lives, including over 16,000 children. Nearly 100,000 people have been wounded, with an
            estimated 10,000 buried under rubble. Despite these devastating challenges, MATW remains committed to providing critical relief:
          </p>
          <ul className="pl-4 lg:pl-8 mt-2">
            <li className="list-disc pl-2">Delivered over 344 trucks of essential relief items</li>
            <li className="list-disc pl-2">Providing daily hot meals</li>
            <li className="list-disc pl-2">Offering consistent food packages</li>
            <li className="list-disc pl-2">Delivering vital medical support</li>
            <li className="list-disc pl-2">Built a village in Gaza sheltering more than 5,000 individuals</li>
          </ul>
          <p className="font-bold  mt-2">The camp isn’t home, Gaza is. In order to stay they need urgent shelter.</p>
          {/*<h6 className="font-bold text-DarkerBlue mt-2">The camp isn’t home, Gaza is. In order to stay they need urgent shelter.</h6>

           <p>Our MATW team has established a large-scale relief camp at Yarmouk Stadium in Gaza City. </p>
          <br />
          <p>
            Equipped with a water well providing clean water and sanitation facilities to help prevent the spread of diseases, this camp aims to
            provide critical assistance to families displaced by recent violence and turmoil.
          </p>
          <h6 className="font-bold text-DarkerBlue mt-2">MATW Camp Infrastructure and Services:</h6>
          <p>
            Whilst this is only a temporary haven, our MATW team has meticulously designed the camp to ensure it delivers vital support to as many of
            our brothers and sisters as possible.
            <br />
            <br />
            The camp includes:
          </p>
          <ul className="flex flex-col gap-y-1">
            <li className="block gap-1">
              <strong className="text-DarkerBlue mr-1">Shelter -</strong>
              500 tents protecting families from harsh weather
            </li>
            <li className="block gap-1">
              <strong className="text-DarkerBlue mr-1">Water -</strong>A central well providing clean, safe drinking water
            </li>
            <li className="block gap-1">
              <strong className="text-DarkerBlue mr-1">Sanitation -</strong>
              Dedicated facilities to maintain hygiene and prevent disease
            </li>
            <li className="block gap-1">
              <strong className="text-DarkerBlue mr-1">Energy -</strong>
              Solar-powered system with mobile charging stations
            </li>
          </ul>
          */}
          <p className="text-DarkerBlue font-bold mt-4">
            Our brothers and sisters in Palestine are hurting and when one part of our Ummah hurts, we all feel the pain. Now is the time to stand
            together to help our brothers and sisters in Palestine.
          </p>
        </div>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title:
        'Our dream is becoming a reality with a five-story orphanage, each floor a sanctuary of 360 square meters, creating a total space of 1,800 square meters. This home will embrace 132 children, offering them warmth, security, and a chance to grow into their full potential.',
      description: (
        <p>
          In addition, a dedicated two-floor medical center will rise, providing vital healthcare services to everyone in need. This facility will
          stand as a promise of health and well-being for the entire community.
          <br />
          <br />
          <strong>Ambitions of the Project</strong>
          <br />
          <ul className="list-disc pl-6">
            <li>Safe Haven: A nurturing environment where orphaned children can feel secure and cherished.</li>
            <li>Healthcare Access: A fully equipped medical center ready to serve the health needs of orphans and local residents.</li>
            <li>Quality Construction: Building with the highest standards of safety and care.</li>
          </ul>
          <br />
          <br />
          <strong>Foreseen Impact</strong>
          <br />
          <br />
          <ul className="list-disc pl-6">
            <li>Improved Lives: Orphans will experience better living conditions and access to education, giving them a brighter future.</li>
            <li>
              Healthcare for All: The medical center will ensure that both the orphanage and the wider community have access to essential healthcare.
            </li>
            <li>Community Spirit: A united community strengthened by the presence of these new facilities.</li>
          </ul>
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title: 'Project Breaking Ground needs $500,000 to provide clean water to 150,000 people.',
      description: (
        <p>
          <strong>The ultimate Sadaqah Jariyah</strong> - $1,000 delivers clean water for 300 people through the construction of the pipelines.
          <br />
          <br />
          <strong>Your ticket to Jannah</strong> - $2,000 delivers clean water for 600 people through the construction of the pipelines.
          <br />
          <br />
          <strong>Immeasurable rewards</strong> - $5,000 delivers clean water for 1,500 people through the construction of the pipelines.
          <br />
          <br />
          <strong>A beautiful Akhirah</strong> - $10,000 delivers clean water for 3,000 people through the construction of the pipelines.
        </p>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title:
        'More than 49,000 Palestinians have lost their lives since October 7, 2023, including over 16,000 children. Nearly 100,000 people have been wounded, with an estimated 10,000 buried under rubble.',
      description: (
        <div>
          <br />
          <p>
            <strong>Despite these devastating challenges, MATW remains committed to providing critical relief:</strong>
          </p>
          <br />
          <ul className="list-disc pl-6">
            <li>Delivered over 344 trucks of essential relief items</li>
            <li>Providing daily hot meals</li>
            <li>Offering consistent food packages</li>
            <li>Delivering vital medical support</li>
            <li>Built a village in Gaza sheltering more than 5,000 individuals.</li>
          </ul>
          <br />
          <br />
          <p>
            Our brothers and sisters in Palestine are hurting and when one part of our Ummah hurts, we all feel the pain. Now is the time to stand
            together to help our brothers and sisters in Gaza. Feed a family, feed orphans, feed thousands by investing in a Sadaqah Jariyah project
            that will continue to earn you rewards.
            <br />
            <br />
            Donate Now.
          </p>
        </div>
      ),
      box_title: '',
      box_description: ``,
    },
    {
      title: 'About the conflict',
      description: (
        <div>
          <br />
          <p>
            Building on this foundation, we will also be providing meaningful support to displaced and struggling families by removing the impossible
            burden of sourcing these essential items during a crisis. Ultimately, these efforts will contribute to improved infant health and hygiene,
            significantly reducing the risks of malnutrition and disease among Gaza's most vulnerable population.
            <br />
            <br />
            To date, MATW has sent more than 344 trucks carrying essential relief items into Gaza. Our team on the ground has also built a village
            sheltering more than 5,000 individuals including 1,000 children. We’re still on the ground delivering right now, trying to reach more of
            our brothers and sisters but we can’t do it alone.
          </p>
          <br />
          <p>
            More than 50,000 Palestinians have lost their lives, including over 16,000 children. More than 100,000 people have been wounded, with an
            estimated 10,000 buried under rubble. Despite these devastating challenges, MATW remains committed to providing critical relief:
          </p>
          <br />
          <ul className="list-disc pl-6">
            <li>Delivered over 344 trucks of essential relief items</li>
            <li>We continue to provide daily hot meals</li>
            <li>We continue delivering food packs to last an entire month</li>
            <li>Our team is delivering medical relief kits</li>
            <li>Delivering shelter and tents</li>
            <li>We have built and continue to operate a village in Gaza that’s home to more than 5,000 individuals.</li>
          </ul>
          <br />
          <br />
          <p>
            Our brothers and sisters in Palestine are hurting and when one part of our Ummah hurts, we all feel the pain. Now is the time to stand
            together to help our brothers and sisters in Gaza.
          </p>
        </div>
      ),
      box_title: '',
      box_description: ``,
    },
  ];
  const [selectedInfo, setSelectedInfo] = useState(moreInfo[0]);
  const suggestedPrices = [50, 150, 250, 500];
  const [selectedPrice, setSelectedPrice] = useState(suggestedPrices[2]);
  const [selectAmount, setSelectAmount] = useState('');

  const suggestedInputsPrices = [
    { value: 30, label: '30' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 15, label: '15' },
    { value: 10, label: '10' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  /* ------------------------------ Start methods ----------------------------- */
  const handlePercentage = (raised, goal) => {
    return `${((Number(raised) / Number(goal)) * 100).toFixed(2)}%`;
  };
  // const handleDaysLeft = () => {
  //   var now = moment(new Date());
  //   // var before = moment(new Date(startDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
  //   // var timeDifference = now.diff(before, 'days');
  //   // var after = moment(new Date(endDateOfAppealCampaign), 'DD-MM-YYYY HH:mm:ss');
  //   var after = moment(
  //     new Date(
  //       campaign === 'limbs-of-hope' ? endDateOfLimbsAppealCampaign : campaign === 'gaza-camp' ? endDateOfGazaCampCampaign : endDateOfAppealCampaign,
  //     ),
  //     'DD-MM-YYYY HH:mm:ss',
  //   );
  //   var timeDifference = after.diff(now, 'days');
  //   return timeDifference;
  // };
  const ShowCurrencies2 = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = '';
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
        break;
      case 'MYR':
        _final = `${price}`;
        break;
      case 'IDR':
        _final = `Rp${price}`;
        break;
      case 'AED':
        _final = `Dh${price}`;
        break;
      default:
        _final = `$${price}`;
        break;
    }
    return _final;
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    switch (campaign) {
      case 'palestine':
        setSelectedInfo(moreInfo[0]);
        break;
      case 'egyptian':
        setSelectedInfo(moreInfo[1]);
        break;
      case 'limbs-of-hope':
        setSelectedInfo(moreInfo[2]);
        break;
      case 'gaza-camp':
        setSelectedInfo(moreInfo[3]);
        break;
      case 'north-lebanon-orphan':
        setSelectedInfo(moreInfo[4]);
        break;
      case 'palestine-water-pipeline':
        setSelectedInfo(moreInfo[5]);
        break;
      case 'gaza-bakery':
        setSelectedInfo(moreInfo[6]);
        break;
      case 'gaza-babies':
        setSelectedInfo(moreInfo[7]);
        break;
      default:
        setSelectedInfo(moreInfo[0]);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */

  return (
    <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 md:gap-8 pb-0 md:py-8">
      {/* {console.log('campp=', campaign, currentProduct)} */}
      {!(campaign === 'palestine-water-pipeline' || campaign === 'gaza-bakery' || campaign === 'gaza-babies' || campaign === 'palestine') && (
        <div className="col-span-2 md:col-span-1">
          <div
            className={`basis-full xs:aspect-w-16  xs:aspect-h-9 sm:h-[300px] md:h-[600px] lg:h-[890px] !max-h-[300px] min-h-fit md:!max-h-[450px]   md:!rounded-lg overflow-hidden`}
          >
            <iframe
              className="video"
              width={'100%'}
              height={'100%'}
              title={'youtube'}
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={
                campaign === 'palestine'
                  ? 'https://youtube.com/embed/8fCYKifjeg4?autoplay=0&feature=oembed'
                  : campaign === 'egyptian'
                  ? 'https://youtube.com/embed/_KD4SL8KDk0?autoplay=0&feature=oembed'
                  : campaign === 'limbs-of-hope'
                  ? 'https://youtube.com/embed/ScnETpaXUSY?autoplay=0&feature=oembed'
                  : campaign === 'gaza-camp'
                  ? 'https://youtube.com/embed/s1NIFIYhC-I?autoplay=0&feature=oembed'
                  : 'https://youtube.com/embed/pyV4cpcqvFE?autoplay=0&feature=oembed'
              }
            />
          </div>
        </div>
      )}
      <div
        className={`${
          campaign === 'palestine-water-pipeline' || campaign === 'gaza-bakery' || campaign === 'gaza-babies' || campaign === 'palestine'
            ? 'col-span-2'
            : 'col-span-2 md:col-span-1'
        } px-4 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0`}
      >
        <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">
          {campaign === 'palestine'
            ? 'Support Orphans in Gaza'
            : campaign === 'egyptian'
            ? 'Help Rebuild Lives for Palestinians escaping conflict'
            : campaign === 'limbs-of-hope'
            ? 'GAZA: Limbs of Hope'
            : campaign === 'palestine-water-pipeline'
            ? 'BREAK GROUND WITH US IN GAZA!'
            : campaign === 'gaza-camp'
            ? 'Deliver Urgent Shelter to protect our brothers and sisters in Gaza!'
            : campaign === 'gaza-bakery'
            ? 'Give the ultimate Sadaqah Jariyah - Build a bakery in Gaza and help feed thousands!'
            : campaign === 'gaza-babies'
            ? `Help the newborns of Gaza live to see tomorrow!`
            : 'North Lebanon Orphan and Medical Centre'}
        </h3>
        <h4 className="text-stone-500">
          {campaign === 'palestine' ? (
            <>
              "In the heart of Gaza's devastation, over <strong>39,000</strong> children have tragically become orphaned and now face unimaginable
              dangers and a future shrouded in uncertainty. <strong> Amid the rubble, they are still the future.</strong> Your compassion can be the
              difference between despair and dignity.{' '}
              <strong> Donate now to protect these children—because in their strength, hope, and resilience lies the future of Gaza.</strong>"
            </>
          ) : campaign === 'egyptian' ? (
            'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
          ) : campaign === 'limbs-of-hope' ? (
            `Deep inside the ruins of Gaza lies a problem not known to many. More than 94,000
                      Palestinians have been severely injured since the beginning of the most recent conflict on
                      October 7, 2023.`
          ) : campaign === 'palestine-water-pipeline' ? (
            'This Ramadan, invest in the ultimate Sadaqah Jariyah project in Gaza - clean water! MATW is breaking ground in three neighbourhoods in Gaza by restoring water infrastructure and building new pipelines. The project will help provide clean water to 150,000 people - imagine the reward Insha’Allah! Your donation right now will not only help provide life’s most basic necessity but also reap immense rewards in the blessed month of Ramadan. Don’t wait. Give like it’s your last Ramadan.'
          ) : campaign === 'gaza-camp' ? (
            `As the conflict once again begins to escalate, our brothers and sisters with Gaza are left with nothing. No basic essentials, no homes. Be the answer to their duas these last 10 nights of Ramadan. Help us provide urgent shelter.`
          ) : campaign === 'gaza-bakery' ? (
            `An end to the ceasefire in Gaza means that once again, supplies are scarce. Access to basic necessities puts strain and dependency on relief from external sources. But what if we could change that? What if we could ensure that our brothers and sisters in Gaza could access a staple food, every single day? MATW’s Bakery Project has arrived in time for Ramadan. This will help feed our brothers and sisters in Gaza and give YOU the opportunity to invest in an incredible project that feeds those who are hungry and builds a beautiful Akhirah. Help us build and run a bakery in Gaza today in these last ten nights of Ramadan.`
          ) : campaign === 'gaza-babies' ? (
            `In a world where conflict and uncertainty loom over Gaza, there are innocent lives that are just beginning—a newborn’s first breath, their first cry, a chance to grow and experience life. These babies, who have done nothing to deserve the violence surrounding them, deserve a chance at a future. Together, we can make sure these newborns are not just born into this world, but are given the gift of life in it. $250 can provide the essentials they need to start their life.`
          ) : (
            `This ambitious project will feature a five-floor orphanage, each floor covering 360 square meters, resulting in a total space of 1,800 square meters. The orphanage will be a haven for 132 children, offering them comfort, care, and the opportunities they need to thrive.
                      Alongside the orphanage, a separate two-floor medical center will be built. This facility will deliver comprehensive healthcare services, ensuring that everyone in the community has access to quality medical care.`
          )}
        </h4>
        {/* {!(campaign === 'palestine-water-pipeline' || campaign === 'limbs-of-hope') && (
          <h4 className="text-stone-500">
            {campaign === 'gaza-bakery'
              ? 'An end to the ceasefire in Gaza means that once again, supplies are scarce. Access to basic necessities puts strain and dependency on relief from external sources. But what if we could change that? What if we could ensure that our brothers and sisters in Gaza could access a staple food, every single day? MATW’s Bakery Project has arrived in time for Ramadan. This will help feed our brothers and sisters in Gaza and give YOU the opportunity to invest in an incredible project that feeds those who are hungry and builds a beautiful Akhirah. Help us build and run a bakery in Gaza today in these last ten nights of Ramadan.'
              : 'As the conflict once again begins to escalate, our brothers and sisters with Gaza are left with nothing. No basic essentials, no homes. Be the answer to their duas these last 10 nights of Ramadan. Help us provide urgent shelter.'
            }
          </h4>
        )} */}
        <div className="w-full flex-col flex justify-center pt-2 md:pt-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex flex-col w-full">
              <div className="text-lg md:text-2xl text-DarkerBlue basis-full font-bold flex items-center">
                {currentProduct && (currentProduct.totalRaised || currentProduct.total_sale)
                  ? ShowCurrencies2(selectedCurrency, Math.round(currentProduct.totalRaised || currentProduct.total_sale), true)
                  : ShowCurrencies2(selectedCurrency, 0, true)}
                {/* {currentProduct && currentProduct.totalRaised ? (
                  ShowCurrencies2(selectedCurrency, Math.round(currentProduct.totalRaised), true)
                ) : (
                  <div className="w-[100px]">
                    <Skeleton height={28} />
                  </div>
                )} */}
                <span className="text-sm ml-0.5">{selectedCurrency}</span>
                <span className="text-sm inline-block lg:w-auto text-stone-500 mx-1 font-medium">Raised of </span>
                <div className="text-DarkerBlue font-bold mr-1">
                  {ShowCurrencies2(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, goal || 500000, false), false))}
                </div>
                <span className="text-sm inline-block lg:w-auto font-medium">goal</span>
              </div>
              {/* <div className="flex items-center gap-1">
                <span className="text-sm inline-block lg:w-auto">Raised of </span>
                <div className="text-DarkerBlue font-bold">
                  {ShowCurrencies2(selectedCurrency, Math.round(ConvertUSD(selectedCurrency, goal || 500000, false), false))}
                </div>
                <span className="text-sm inline-block lg:w-auto">goal</span>
              </div> */}
            </div>
          </div>
          <div className="flex w-full h-2 bg-[#E3E3E3] bg-opacity-10- mb-2">
            {currentProduct && (
              <div
                className={`bg-[#F60362]`}
                style={{
                  width: `${handlePercentage(
                    currentProduct ? currentProduct.totalRaised || currentProduct.total_sale : 0,
                    ConvertUSD(selectedCurrency, goal, false),
                  )}`,
                }}
              />
            )}
          </div>
          <div className="flex w-full text-xs">
            <div className="text-DarkerBlue font-bold mr-0.5">
              {currentProduct ? currentProduct.totalSupporters || currentProduct.total_count : 0}
            </div>
            <span className="inline-block lg:w-auto mr-1">Supporters,</span>
            <div className="text-DarkerBlue font-bold mr-0.5">{handleDaysLeft(campaign)}</div>
            <span className="inline-block lg:w-auto">days left</span>
          </div>
        </div>

        <div
          className={`w-full grid ${
            campaign === 'palestine-water-pipeline' ? 'grid-cols-6 md:grid-cols-3' : 'grid-cols-3'
          } gap-2 md:gap-3 my-2 md:my-4 md:max-w-[520px] lg:pr-3`}
        >
          {suggestedPrices.map((item, index) => {
            if (index > 3 && campaign !== 'palestine-water-pipeline') {
              return;
            } else {
            }
            return (
              <div
                key={`prices${index}`}
                onClick={() => setSelectedPrice(item)}
                className={`${
                  campaign === 'palestine-water-pipeline' ? 'col-span-3 md:col-span-1' : 'col-span-1'
                } w-full cursor-pointer transition-all duration-300 border border-LightBlue rounded-md flex justify-center items-center py-2 h-[42px] ${
                  selectedPrice === item ? 'bg-LightBlue text-white' : 'bg-white text-LightBlue'
                }`}
              >
                {ShowCurrencies2(selectedCurrency, item, true)}
                <small className="ml-0.5 md:ml-1 mt-0.5">{selectedCurrency}</small>
              </div>
            );
          })}
          <>
            <SelectCurrency
              onChangeCurrency={e => setSelectedCurrency(e.value)}
              classNamePrefix="orphan_select- orphan_select--ghaza"
              className={`!w-full !text-sm ${campaign === 'palestine-water-pipeline' ? 'col-span-3 md:col-span-1' : ''}`}
            />
            <div
              className={`${
                campaign === 'palestine-water-pipeline'
                  ? 'col-span-3 md:col-span-1 lg:col-span-1'
                  : 'col-span-2 sm:col-span-1 md:col-span-2 lg:col-span-1'
              } relative h-[42px] flex border border-[#ccc] focus:ring-transparent focus:border-LightBlue rounded-[.375rem] w-full`}
            >
              <input
                value={selectAmount}
                name="amount"
                className="flex-1 text-[#777] w-full truncate p-2 text-sm focus:outline-none focus:ring-1 focus:ring-transparent !border-none rounded-[.375rem]"
                type="number"
                min={'0'}
                onChange={e => {
                  const value = e.target.value;

                  // Update the state only if the value is valid
                  const regex = /^(?:[1-9]\d*)?$/;
                  if (regex.test(value)) {
                    setSelectAmount(value);
                    setSelectedPrice(value ? parseInt(value, 10) : suggestedPrices[2]); // Update selectedPrice with the custom value
                  }
                }}
                placeholder="Other Amount"
              />
              <div classNames="absolute right-0 top-0 h-[42px]">
                <ReactSelect
                  defaultValue={selectAmount}
                  onChange={selectedOption => {
                    setSelectAmount(selectedOption.value);
                    setSelectedPrice(selectedOption.value);
                    setSelectedOption(selectedOption);
                  }}
                  classNamePrefix="price-select"
                  options={suggestedInputsPrices}
                  isSearchable={false}
                  placeholder=""
                  isClearable={false} // Remove clear icon
                  className="notranslate max-h-[42px] [&>*]:!cursor-pointer !w-full [&>div]:!rounded-md !max-w-6"
                />
              </div>
            </div>
            <CustomButton
              {...(selectedGivingProducts.length
                ? { link: '/checkout' } // Navigate to '/checkout' if products exist
                : {
                    onClick: () =>
                      addProduct({
                        product: currentProduct,
                        currency: selectedCurrency,
                        defaultPrice: selectedPrice,
                      }), // Add product if no products exist
                  })}
              title={'Donate Now'}
              disabled={!currentProduct}
              type="button"
              className={`w-full ${
                campaign === 'palestine-water-pipeline'
                  ? 'col-span-6 md:col-span-3 lg:col-span-1'
                  : 'col-span-3 sm:col-span-1 md:col-span-3 lg:col-span-1'
              } !h-[42px]`}
            />
          </>
        </div>

        <div className="w-full rounded-md px-3 h-[38px] bg-[#80ceff] flex items-center justify-center sm:hidden">
          <img src={creditImg} alt="MATW" className="max-w-full h-auto max-h-[38px]" />
        </div>
        <div className="w-full relative">
          <div className={`my-2 relative overflow-hidden h-auto mb-4`}>
            <p className={`text-[#78716C] text-xs- sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}>
              {campaign === 'palestine' ? (
                'Your support can be the lifeline they desperately need. By providing food and shelter, you can offer them not just survival, but a glimmer of hope for a brighter future. Together, we can make a profound difference in their lives because no child should have to face the world alone'
              ) : campaign === 'egyptian' ? (
                'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
              ) : campaign === 'limbs-of-hope' ? (
                ``
              ) : campaign === 'palestine-water-pipeline' ? (
                'This Ramadan, invest in the ultimate Sadaqah Jariyah project in Gaza - clean water! MATW is breaking ground in three neighbourhoods in Gaza by restoring water infrastructure and building new pipelines. The project will help provide clean water to 150,000 people - imagine the reward Insha’Allah! Your donation right now will not only help provide life’s most basic necessity but also reap immense rewards in the blessed month of Ramadan. Don’t wait. Give like it’s your last Ramadan.'
              ) : campaign === 'gaza-camp' ? (
                <span>
                  After almost two years of brutal conflict and unimaginable suffering, Palestinian families began returning to what was left of their
                  homes. With almost everything destroyed, they now face a new escalation without shelter. Resilient, our brothers and sisters in Gaza
                  remain determined to survive and stay.
                  <br />
                  <br />
                  To date, MATW has sent more than 344 trucks carrying essential relief items into Gaza. Our team on the ground has also built a
                  village sheltering more than 5,000 individuals including 1,000 children. We’re still on the ground delivering right now, trying to
                  reach more of our brothers and sisters but we can’t do it alone. In these last ten nights, use your Zakat and Sadaqah to save lives
                  and multiply your rewards.
                </span>
              ) : campaign === 'gaza-bakery' ? (
                <span>
                  In Gaza, the ongoing blockades and fuel shortages have led to the closure of over 98% of local bakeries, severely impacting access
                  to fresh, affordable bread for many families. Bread is a staple food in the region, and its scarcity exacerbates food insecurity,
                  especially among those who are most vulnerable including orphaned children and widows. Our beloved Prophet Muhammad (PBUH)
                  emphasized the significance of feeding those who are hungry. The reward for that is even greater during the month of Ramadan,
                  especially now in these final ten nights of Ramadan.
                  <br />
                  <br />
                  MATW’s Gaza Bakery Project addresses the urgent need for food security while fostering economic stability in a region facing immense
                  challenges. The project aims to create a sustainable solution for the people of North Gaza. By establishing a fully operational
                  bakery, the project will produce high-quality bread and pastries, providing essential nutrition to over 1,200 children and local
                  families every day. The bakery will also offer employment to widows and vulnerable individuals, allowing them to earn stable incomes
                  and regain economic independence. Through a well-structured plan that includes infrastructure upgrades, energy-efficient ovens, and
                  local staff training, the bakery will operate efficiently despite the ongoing fuel shortages. The long-term goal is to achieve
                  financial sustainability through commercial sales within one year, ensuring continued support for the local economy and community
                  development.
                </span>
              ) : campaign === 'gaza-babies' ? (
                <span>
                  Almost two years of conflict and unimaginable suffering has had a devastating effect on new mothers and their babies in Gaza. Our
                  newborn emergency support program will provide nutritional and hygiene care for newborn babies. The program is strategically
                  designed to deliver maximum impact and will distribute essential supplies to <strong>10,000 vulnerable newborns</strong>.
                  <br />
                  <br />
                  Our MATW team will ensure they receive critical supplies including much-needed nutritional formula, appropriate clothing, and
                  sufficient diapers to meet their basic needs.
                </span>
              ) : (
                `This ambitious project will feature a five-floor orphanage, each floor covering 360 square meters, resulting in a total space of 1,800 square meters. The orphanage will be a haven for 132 children, offering them comfort, care, and the opportunities they need to thrive.
                      Alongside the orphanage, a separate two-floor medical center will be built. This facility will deliver comprehensive healthcare services, ensuring that everyone in the community has access to quality medical care.`
              )}
            </p>
          </div>
        </div>
        <CustomButton
          onClick={() => setShowInfo(!showInfo)}
          title={'Click to know more'}
          icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 ${showInfo ? ' transform rotate-180' : ''}`} />}
          type="button"
          className={`!bg-LightBlue !text-[10px] !h-8`}
        />
        <div
          className={`flex flex-col overflow-hidden w-full bg-stone-100- rounded-md ${
            showInfo ? 'min-h-[600px] py-4 md:py-8' : 'h-0 py-0'
          } transition-all duration-200`}
        >
          <div className={`text-2xl md:text-4xl mb-4 ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} text-DarkerBlue`}>
            {selectedInfo.title}
          </div>
          <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
            {selectedInfo.description}
            {/* {<RawHTML>{selectedInfo.description}</RawHTML>} */}
          </div>
          <div className="bg-white rounded-md shadow-md mt-2 grid grid-cols-2 gap-2 md:p-4">
            <div className="col-span-2">
              <div className={`text-DarkerBlue text-4xl ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-gotcha- font-brandingBold'}`}>
                {selectedInfo.box_title}
              </div>
            </div>
            <div className="col-span-2 mb-4">
              <div className={`text-[#78716C] md:text-lg ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                {selectedInfo.box_description}
              </div>
            </div>
          </div>
          <CustomButton
            onClick={() => setShowInfo(!showInfo)}
            title={'Close'}
            icon={<ChevronDownIcon className={`w-5 h-5 transition-all duration-300 cursor-pointer ml-1 transform rotate-180`} />}
            type="button"
            className={`!bg-white !text-LightBlue !text-[10px] !h-8 shadow-md`}
          />
        </div>
      </div>
    </section>
  );
};

export default AppealCurrentProductUAT;
